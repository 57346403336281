import { FC } from 'react';
import Picture from '../Picture';
import s from './CoverBg.module.scss';

interface Props {
  png: string;
  webp: string;
}

const CoverBg: FC<Props> = (props) => {
  const { png, webp } = props;

  return <Picture className={s.bg} png={png} webp={webp} def alt="Задний фон" />;
};

export default CoverBg;
