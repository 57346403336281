import s from './Delivery.module.scss';

import { FC, useContext } from 'react';
import { cssm } from '../../../../hooks/css.hooks';

import Img from '../../../../assets/img/sections/delivery-img.png';
import Mob from '../../../../assets/img/sections/delivery-img-mob.png';
import ImgW from '../../../../assets/img/sections/delivery-img.webp';
import MobW from '../../../../assets/img/sections/delivery-img-mob.webp';
import { Context } from '../../../../app/Application';
import Picture from '../../../../components/Picture';

const Delivery: FC = () => {
  const { setModalCTX, i18n } = useContext(Context);
  return (
    <section id="delivery" className={s.delivery}>
      <Picture className={s.mob} png={Mob} webp={MobW} alt="Салаты" />
      <div className={cssm(s.container, 'container')}>
        <h2 className={s.title}>{i18n.__('delivery-title')}</h2>
        <button onClick={() => setModalCTX(true)} className={cssm(s.btn, 'button')}>
          {i18n.__('delivery-btn')}
        </button>
        <ul className={s.list}>
          <li>{i18n.__('delivery-item-1')}</li>
          <li>{i18n.__('delivery-item-2')}</li>
          <li>{i18n.__('delivery-item-3')}</li>
        </ul>
        <Picture className={s.img} png={Img} webp={ImgW} alt="Салаты" />
      </div>
    </section>
  );
};

export default Delivery;
