import s from './About.module.scss';

import { FC, useContext } from 'react';
import { cssm } from '../../../../hooks/css.hooks';
import { IconPlayBtn } from '../../../../components/icons';
import Picture from '../../../../components/Picture';
import CoverBg from '../../../../components/CoverBg';

import Img from '../../../../assets/img/sections/about-img.png';
import ImgW from '../../../../assets/img/sections/about-img.webp';
import Bg from '../../../../assets/img/sections/about-bg.png';
import BgW from '../../../../assets/img/sections/about-bg.webp';
import { Context } from '../../../../app/Application';

const About: FC = () => {
  const { i18n } = useContext(Context);
  const configList = [
    i18n.__('about-item-1'),
    i18n.__('about-item-2'),
    i18n.__('about-item-3'),
    i18n.__('about-item-4'),
    i18n.__('about-item-5'),
  ];

  return (
    <section id="manufacturing" className={s.about}>
      <CoverBg png={Bg} webp={BgW} />
      <div className={cssm(s.container, 'container')}>
        <h2>{i18n.__('about-title')}</h2>
        <ol>
          {configList.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
        <a href="#video" className={cssm(s.link, 'link')}>
          {i18n.__('about-btn')} <IconPlayBtn />
        </a>
        <div className={s.descr}>
          <Picture png={Img} webp={ImgW} alt="Фон" />
          <p>
            <b>{i18n.__('about-descr-b')}</b> <br />
            <span>{i18n.__('about-descr')}</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
