import s from './VideoSection.module.scss';
import { FC } from 'react';

const VideoSection: FC = () => {
  return (
    <section className={s.videoSection}>
      <div className={s.container}>
        <h2 className={s.title}>Интервью с основателем Vitamincus</h2>
        <h3 className={s.subtitle}>Роман Моисеев делится историей создания бренда и ключевыми ценностями компании</h3>
        <div className={s.videoWrapper}>
          <iframe
            className={s.video}
            src="https://www.youtube.com/embed/F-c-tx9D5W0?si=qRW75kwUjQhuQ2X5"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;