import { FC, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import ModalBuy from '../../components/ModalBuy/ModalBuy';
import { Context } from '../Application';

import Footer from './Footer/Footer';
import Header from './Header/Header';

const Layout: FC = () => {
  const { i18n } = useContext(Context);
  const currLang = i18n.language;
  const isRu = currLang === 'ru';

  const pathname = useLocation().pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>
          {isRu
            ? 'Vitamincus - полезные и вкусные салаты из проростков семян и микрозелени'
            : 'Vitamincus - healthy and tasty salads from sprouted seeds and microgreens'}
        </title>
        <meta
          name="description"
          content={
            isRu
              ? 'Полезные ростковые салаты на каждый день. Можно заказать с доставкой или приобрести в торговых сетях.'
              : 'Healthy sprout salads for every day. Can be ordered for delivery or purchased from retailers.'
          }
        />
        <meta
          property="og:title"
          content={
            isRu
              ? 'Vitamincus - полезные и вкусные салаты из проростков семян и микрозелени'
              : 'Vitamincus - healthy and tasty salads from sprouted seeds and microgreens'
          }
        />
        <meta
          property="og:description"
          content={
            isRu
              ? 'Полезные ростковые салаты на каждый день. Можно заказать с доставкой или приобрести в торговых сетях.'
              : 'Healthy sprout salads for every day. Can be ordered for delivery or purchased from retailers.'
          }
        />
        <link rel="canonical" href="https://vitamincus.me" />
        <meta property="og:url" content="https://vitamincus.me" />
        <meta property="og:image" content="./favicon/android-chrome-512x512.png" />
        <meta property="og:image" content="./favicon/android-chrome-512x512.png" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:locale" content={currLang === 'ru' ? 'ru_RU' : 'en_EN'} />
        <meta
          property="og:site_name"
          content={
            isRu
              ? 'Полезные и вкусные салаты из проростков семян и микрозелени'
              : 'Healthy and tasty salads from sprouted seeds and microgreens'
          }
        />
      </Helmet>
      <Header />
      <Outlet />
      <Footer />
      <ModalBuy />
    </>
  );
};

export default Layout;
