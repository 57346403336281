import s from './Catalog.module.scss';

import { cssm } from '../../../../hooks/css.hooks';
import { IconSliderArrow } from '../../../../components/icons';
import { useState, FC, useRef, useEffect, useContext } from 'react';
import {
  Item1,
  Item2,
  Item3,
  Item4,
  Item5,
  Item6,
  Item7,
  Item8,
  Item9,
  Item10,
  Item11,
  Item12,
  Item13,
  Item14,
  Item7en,
  Item8en,
  Item9en,
  ItemW1,
  ItemW2,
  ItemW3,
  ItemW4,
  ItemW5,
  ItemW6,
  ItemW7,
  ItemW7en,
  ItemW8,
  ItemW8en,
  ItemW9,
  ItemW9en,
  ItemW10,
  ItemW11,
  ItemW12,
  ItemW13,
  ItemW14,
} from '../../../../assets/img/catalog/index';
import Picture from '../../../../components/Picture';
import CoverBg from '../../../../components/CoverBg';

import Bg from '../../../../assets/img/sections/catalog-bg.png';
import BgW from '../../../../assets/img/sections/catalog-bg.webp';
import { Context } from '../../../../app/Application';

const Catalog: FC = () => {
  const { i18n } = useContext(Context);
  const calcCount = (arr: any[], type: string) => {
    return arr.filter((item) => item.type === type).length;
  };

  const catalogConfig = [
    {
      type: 'mix',
      name: i18n.__('catalog-7-name'),
      img: i18n.language === 'ru' ? Item7 : Item7en,
      webp: i18n.language === 'ru' ? ItemW7 : ItemW7en,
      descr: i18n.__('catalog-7-descr'),
      protein: 9.01,
      fats: 13.66,
      carboh: 8.73,
    },
    {
      type: 'mix',
      name: i18n.__('catalog-8-name'),
      img: i18n.language === 'ru' ? Item8 : Item8en,
      webp: i18n.language === 'ru' ? ItemW8 : ItemW8en,
      descr: i18n.__('catalog-8-descr'),
      protein: 7.35,
      fats: 7.5,
      carboh: 8.8,
    },
    {
      type: 'mix',
      name: i18n.__('catalog-9-name'),
      img: i18n.language === 'ru' ? Item9 : Item9en,
      webp: i18n.language === 'ru' ? ItemW9 : ItemW9en,
      descr: i18n.__('catalog-9-descr'),
      protein: 2.7,
      fats: 0.18,
      carboh: 0.36,
    },
    {
      type: 'mix',
      name: i18n.__('catalog-1-name'),
      img: Item1,
      webp: ItemW1,
      descr: i18n.__('catalog-1-descr'),
      protein: 3.75,
      fats: 0.73,
      carboh: 7.04,
    },
    {
      type: 'mix',
      name: i18n.__('catalog-2-name'),
      img: Item2,
      webp: ItemW2,
      descr: i18n.__('catalog-2-descr'),
      protein: 3.51,
      fats: 0.71,
      carboh: 7.09,
    },
    {
      type: 'mix',
      name: i18n.__('catalog-3-name'),
      img: Item3,
      webp: ItemW3,
      descr: i18n.__('catalog-3-descr'),
      protein: 3.83,
      fats: 0.71,
      carboh: 7.1,
    },
    {
      type: 'mix',
      name: i18n.__('catalog-4-name'),
      img: Item4,
      webp: ItemW4,
      descr: i18n.__('catalog-4-descr'),
      protein: 1.0,
      fats: 25.0,
      carboh: 34.0,
    },
    {
      type: 'mix',
      name: i18n.__('catalog-5-name'),
      img: Item5,
      webp: ItemW5,
      descr: i18n.__('catalog-5-descr'),
      protein: 2.29,
      fats: 0.22,
      carboh: 3.16,
    },
    {
      type: 'mix',
      name: i18n.__('catalog-6-name'),
      img: Item6,
      webp: ItemW6,
      descr: i18n.__('catalog-6-descr'),
      protein: 7.22,
      fats: 9.27,
      carboh: 7.18,
    },
    {
      type: 'sprouts',
      name: i18n.__('catalog-10-name'),
      img: Item10,
      webp: ItemW10,
      descr: i18n.__('catalog-10-descr'),
      protein: 9.0,
      fats: 0.6,
      carboh: 22.1,
    },
    {
      type: 'sprouts',
      name: i18n.__('catalog-11-name'),
      img: Item11,
      webp: ItemW11,
      descr: i18n.__('catalog-11-descr'),
      protein: 2.1,
      fats: 0.35,
      carboh: 2.4,
    },
    {
      type: 'sprouts',
      name: i18n.__('catalog-12-name'),
      img: Item12,
      webp: ItemW12,
      descr: i18n.__('catalog-12-descr'),
      protein: 3.7,
      fats: 0.48,
      carboh: 13.73,
    },
    {
      type: 'seedling',
      name: i18n.__('catalog-13-name'),
      img: Item13,
      webp: ItemW13,
      descr: i18n.__('catalog-13-descr'),
      protein: 7.92,
      fats: 0.61,
      carboh: 24.04,
    },
    {
      type: 'seedling',
      name: i18n.__('catalog-14-name'),
      img: Item14,
      webp: ItemW14,
      descr: i18n.__('catalog-14-descr'),
      protein: 8.1,
      fats: 0.54,
      carboh: 19.89,
    },
  ];

  const tabsConfig = [
    { name: i18n.__('catalog-tab-1'), type: 'mix', count: calcCount(catalogConfig, 'mix') },
    { name: i18n.__('catalog-tab-2'), type: 'sprouts', count: calcCount(catalogConfig, 'sprouts') },
    {
      name: i18n.__('catalog-tab-3'),
      type: 'seedling',
      count: calcCount(catalogConfig, 'seedling'),
    },
  ];

  const [activeType, setActiveType] = useState<string>('mix');
  const [isScrolling, setScrolling] = useState<boolean>(true);
  const [productsWidth, setProductsWidth] = useState<number>(292);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setProductsWidth(ref.current?.children[0].getBoundingClientRect().width ?? 292);
    setScrolling(true);
  }, [ref]);

  const onChangeProducts = (e: React.MouseEvent<HTMLLIElement>): void => {
    const currentType = e.currentTarget.getAttribute('data-type');
    const productsLength = calcCount(catalogConfig, currentType ?? 'mix');
    setActiveType(currentType ?? 'mix');
    setScrolling(productsWidth ? productsWidth * productsLength > window.innerWidth : false);
  };

  const onClickNext = () => {
    if (ref?.current) {
      const scrollLeft = ref?.current.scrollLeft;
      const cardWidth = ref.current?.children[0].getBoundingClientRect().width;

      ref.current.scrollTo({
        left: scrollLeft + cardWidth,
        behavior: 'smooth',
      });
    }
  };

  const onClickPrev = () => {
    if (ref?.current) {
      const scrollLeft = ref?.current.scrollLeft;
      const cardWidth = ref.current?.children[0].getBoundingClientRect().width;
      ref.current.scrollTo({
        left: scrollLeft - cardWidth,
        behavior: 'smooth',
      });
    }
  };

  const productsView = catalogConfig.filter((product) => product.type === activeType);

  return (
    <section id="catalog" className={s.catalog}>
      <CoverBg png={Bg} webp={BgW} />
      <div className={cssm(s.container, 'container')}>
        <ul className={s.tabs}>
          {tabsConfig.map((tab) => (
            <li
              key={tab.type}
              onClick={onChangeProducts}
              data-type={tab.type}
              className={tab.type === activeType ? s.active : ''}>
              {tab.name} <i>({tab.count})</i>
            </li>
          ))}
        </ul>
        {isScrolling && (
          <div className={s.arrows}>
            <div onClick={onClickPrev} className={cssm(s.prev, 'button')}>
              <IconSliderArrow />
            </div>
            <div onClick={onClickNext} className={cssm(s.next, 'button')}>
              <IconSliderArrow />
            </div>
          </div>
        )}
      </div>
      <div ref={ref} className={s.wrapper}>
        {productsView.map((product, i) => (
          <div key={i} className={s.card}>
            <b>{product.name}</b>
            <div className={s.content}>
              <Picture png={product.img} webp={product.webp} alt={product.name} />
              <div className={s.overlay}>
                <p>{product.descr}</p>
                <div className={s.table}>
                  <span>{i18n.__('catalog-label')}</span>
                  <strong>
                    {product.protein} | {product.fats} | {product.carboh}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Catalog;
