import s from './Blog.module.scss';

import { FC, useContext } from 'react';
import { cssm } from '../../../../hooks/css.hooks';
import { IconDzen, IconVK } from '../../../../components/icons';

import { Context } from '../../../../app/Application';
import { Link } from 'react-router-dom';
import Bg from '../../../../assets/img/sections/blog-bg.png';
import BgW from '../../../../assets/img/sections/blog-bg.webp';
import Picture from '../../../../components/Picture';
import CoverBg from '../../../../components/CoverBg';

interface BlogProps {
  articles: any[];
}

const Blog: FC<BlogProps> = (props) => {
  const { i18n } = useContext(Context);
  const { articles } = props;
  const currLang = i18n.language;

  return (
    <section id="#blog" className={s.blog}>
      <CoverBg png={Bg} webp={BgW} />
      <div className={cssm(s.container, 'container')}>
        <div className={s.content}>
          <h2 className={s.title}>{i18n.__('blog-title')}</h2>
          <div className={s.foot}>
            <Link className={cssm(s.link, 'link')} to="/blog">
              {i18n.__('blog-link')}
            </Link>
            {currLang === 'ru' && (
              <ul className={s.socials}>
                <li>
                  <a
                    className={cssm(s.vk, 'link')}
                    href="https://vk.com/vitamincus"
                    target="_blank"
                    rel="nofollow noreferrer">
                    <IconVK />
                  </a>
                </li>
                <li>
                  <a
                    className={cssm(s.dzen, 'link')}
                    href="https://dzen.ru/id/623d98ad597c2c26208a333c"
                    target="_blank"
                    rel="nofollow noreferrer">
                    <IconDzen />
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className={s.sidebar}>
          {articles.map((article) => (
            <Link className={s.card} key={article.id} to={`/blog/${article.id}`}>
              <div className={s.img}>
                <Picture webp={article.bg[1]} png={article.bg[0]} alt={article.name} />
              </div>
              <b>{article.name}</b>
              <i>{article.date}</i>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
