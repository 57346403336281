import s from './SideDish.module.scss';

import { FC, useContext } from 'react';
import ImgP from '../../../../assets/img/sections/salats-img.png';
import { cssm } from '../../../../hooks/css.hooks';

import { Context } from '../../../../app/Application';

const SideDish: FC = () => {
  const { i18n } = useContext(Context);
  const currLang = i18n.language;
  const isRu = currLang === 'ru';

  return (
    <>
      {isRu && (
        <section className={s.brand}>
          <div className={cssm(s.my_container, 'container')}>
            <div className={s.wrap}>
              <h2 className={s.title}>
                Праздник Здоровья <br /> с 2 000 000 Салатов
              </h2>
              <div className={s.text}>
                <p>
                  Мы в Vitamincus радостно объявляем о превосходном достижении, которое вдохновляет
                  нас продолжать наш путь в здоровом питании: более 2 миллионов салатов из
                  микрозелени нашли своих ценителей!
                  <br />
                  Этот великолепный результат не просто цифра — это отражение доверия и преданности
                  наших клиентов, которые выбирают качество и свежесть на каждом своем столе.
                  <br />
                  Покрытие Регионов
                  <br />С гордостью осваивая новые горизонты, Vitamincus охватывает все более
                  широкую географию. Благодаря вашему выбору в пользу здоровья, наши салаты сегодня
                  доступны в таких ключевых регионах, как Казань, Нижний Новгород, Краснодар и во
                  всем Урале. Мы продолжим нашу миссию, обеспечивая каждый регион самыми свежими и
                  питательными салатами.
                </p>
              </div>
            </div>
            <img className={s.img} src={ImgP} alt="Микрозелень" />
            <ul className={s.list}>
              <li>Пермь</li>
              <li>Казань</li>
              <li>Москва</li>
              <li>Тюмень</li>
              <li>Краснодар</li>
              <li>Челябинск</li>
              <li>Екатеринбург</li>
              <li>Санкт-Петербург</li>
              <li>Нижний Новгород</li>
            </ul>
          </div>
        </section>
      )}
    </>
  );
};

export default SideDish;
