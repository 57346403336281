import s from './ModalBuy.module.scss';
import {
  Img1,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  ImgW1,
  ImgW2,
  ImgW3,
  ImgW4,
  ImgW5,
  ImgW6,
  ImgW7,
  ImgW8,
} from '../../assets/img/partners';
import { FC, useContext } from 'react';
import { cssm } from '../../hooks/css.hooks';
import { Context } from '../../app/Application';
import { IconClose } from '../icons';
import Picture from '../Picture';

const configPartners = [
  { link: 'https://av.ru/search/?text=vitamincus', img: Img1, webp: ImgW1 },
  { link: 'https://www.vprok.ru/catalog/search?text=vitamincus', img: Img2, webp: ImgW2 },
  { link: 'https://samokat.ru/', img: Img3, webp: ImgW3 },
  { link: 'https://www.ozon.ru/', img: Img4, webp: ImgW4 },
  { link: 'https://www.okmarket.ru/', img: Img5, webp: ImgW5 },
  { link: 'https://www.globus.ru/', img: Img6, webp: ImgW6 },
  { link: 'https://myspar.ru/', img: Img7, webp: ImgW7 },
  { link: 'https://5ka.ru/', img: Img9, webp: Img9 },
  { link: 'https://growfood.pro/', img: Img10, webp: Img10 },
  { link: 'https://www.perekrestok.ru/cat/search?search=vitamincus', img: Img11, webp: Img11 },
  { link: 'https://sbermarket.ru/', img: Img12, webp: Img12 },
  { link: 'https://magnit.ru/', img: Img13, webp: Img13 },
  { link: 'https://vkusvill.ru/', img: Img14, webp: Img14 },
  { link: 'https://online.metro-cc.ru/', img: Img15, webp: Img15 },
  
];

// const configPartnersEN = [{ link: 'https://yallamarket.ae/', img: Img8, webp: ImgW8 }];

const ModalBuy: FC = () => {
  const { isModalCTX, setModalCTX, i18n } = useContext(Context);
  const currLang = i18n.language;

  return (
    <div className={cssm(isModalCTX ? s.active : '', s.modal)}>
      <div className={s.overlay} onClick={() => setModalCTX(false)} />
      <div className={s.content}>
        <div className={s.close} onClick={() => setModalCTX(false)}>
          <IconClose />
        </div>
        <div className={s.title}>{i18n.__('modal-title')}</div>
        <div className={s.wrapper}>
          {currLang === 'ru' &&
            configPartners.map((item, i) => (
              <a key={i} target="_blank" rel="noreferrer nofollow" href={item.link}>
                <Picture png={item.img} webp={item.webp} alt={item.link} />
              </a>
            ))}
          {/* {currLang === 'en' &&
            configPartnersEN.map((item, i) => (
              <a key={i} target="_blank" rel="noreferrer" href={item.link}>
                <Picture png={item.img} webp={item.webp} alt={item.link} />
              </a>
            ))} */}
          {currLang !== 'ru' && (
            <a target="_blank" rel="noreferrer" href="https://yallamarket.ae/">
              <Picture png={Img8} webp={ImgW8} alt="yallamarket" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalBuy;
