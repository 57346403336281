import s from './BlogPage.module.scss';

import { FC, useContext } from 'react';
import { cssm } from '../../hooks/css.hooks';
import { Link } from 'react-router-dom';
import Offer from '../../components/Offer/Offer';
import { HelmetHead } from '../../seo';
import { Context } from '../../app/Application';
import Picture from '../../components/Picture';

interface BlogPageProps {
  articles: any[];
}

const BlogPage: FC<BlogPageProps> = (props) => {
  const { articles } = props;
  const { i18n } = useContext(Context);

  return (
    <>
      <HelmetHead
        titles={['Блог Vitamincus', 'Vitamincus Blog.']}
        descrs={[
          'Вкусно пишем о микрозелени и не только! ЗОЖ, спорт, питание, саморазвитие и прочие важные темы в нашем блоге',
          'Delicious writing about microgreens and more! Healthy living, sports, nutrition, self-development and other important topics on our blog',
        ]}
      />
      <main>
        <Offer title={i18n.__('blogs-title')} />
        <section className={s.articles}>
          <div className={cssm(s.container, 'container')}>
            {articles.map((article) => (
              <Link className={s.card} key={article.id} to={`/blog/${article.id}`}>
                <div className={s.img}>
                  <Picture png={article.bg[0]} webp={article.bg[1]} alt={article.name} />
                </div>
                <b>{article.name}</b>
                <i>{article.date}</i>
              </Link>
            ))}
          </div>
        </section>
      </main>
    </>
  );
};

export default BlogPage;
