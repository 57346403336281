import s from './NotFoundPage.module.scss';

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { cssm } from '../../hooks/css.hooks';
import { HelmetHead } from '../../seo';

const NotFoundPage: FC = () => {
  return (
    <>
      <HelmetHead
        titles={['404', '404']}
        descrs={['Такой страницы не существует', 'Such a page does not exist']}
      />
      <main className={s.notpage}>
        <div className="container">
          <div className={s.title}>404</div>
          <Link className={cssm(s.back, 'button')} to="/">
            Перейти на главную
          </Link>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
