import s from './ArticlePage.module.scss';

import { FC, useContext } from 'react';
import { IconDzen, IconVK } from '../../components/icons';
import { cssm } from '../../hooks/css.hooks';
import { useParams } from 'react-router-dom';

import { HelmetHead } from '../../seo';
import Picture from '../../components/Picture';
import { Context } from '../../app/Application';

interface ArticlePageProps {
  articles: any[];
}

const ArticlePage: FC<ArticlePageProps> = (props) => {
  const { i18n } = useContext(Context);
  const { articleID } = useParams();
  const { articles } = props;
  const article = articles.filter((article) => String(article.id) === articleID)[0];
  const currLang = i18n.language;
  const domain = window.location.origin;

  const { name, bg, content, descr, title } = article;

  return (
    <>
      <HelmetHead titles={[title, title]} descrs={[descr, descr]} img={domain + bg[0]} />
      <main>
        <section className={s.offer}>
          {bg && (
            <div className={s.bg}>
              <Picture png={bg[0]} webp={bg[1]} />
            </div>
          )}
          <div className="container">
            <h1 className={s.title}>{name}</h1>
            {currLang === 'ru' && (
              <ul className={s.socials}>
                <li>
                  <a
                    className={cssm(s.vk, 'link')}
                    href="https://vk.com/vitamincus"
                    target="_blank"
                    rel="nofollow noreferrer">
                    <IconVK />
                  </a>
                </li>
                <li>
                  <a
                    className={cssm(s.dzen, 'link')}
                    href="https://dzen.ru/id/623d98ad597c2c26208a333c"
                    target="_blank"
                    rel="nofollow noreferrer">
                    <span>{i18n.__('offer-dzen')}</span> <IconDzen />
                  </a>
                </li>
              </ul>
            )}
          </div>
        </section>
        <section className={s.article}>
          <div className={cssm('container', s.container)}>
            <div className={s.text}>{content}</div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ArticlePage;
