import s from './Offer.module.scss';

import { FC, useContext, useEffect, useState } from 'react';
import { IconDzen, IconVK } from '../../components/icons';
import { cssm } from '../../hooks/css.hooks';
import mp4 from '../../assets/video/bg.mp4';
import { Context } from '../../app/Application';
import Bg from '../../assets/img/sections/offer-bg.png';
import BgW from '../../assets/img/sections/offer-bg.webp';
import CoverBg from '../CoverBg';

interface OfferProps {
  title: string;
}

const Offer: FC<OfferProps> = (props) => {
  const { title } = props;
  const { setModalCTX, i18n } = useContext(Context);
  const currLang = i18n.language;
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    const renderVideo = setTimeout(() => {
      setIsVideo(true);
    }, 2500);
    return () => clearInterval(renderVideo);
  });

  return (
    <section id="video" className={s.offer}>
      <div className={cssm(s.container, 'container')}>
        <h1>{title}</h1>
        <div className={s.foot}>
          <button onClick={() => setModalCTX(true)} className={cssm(s.btn, 'button')}>
            {i18n.__('offer-btn')}
          </button>
          {currLang === 'ru' && (
            <ul className={s.socials}>
              <li>
                <a
                  className={cssm(s.vk, 'link')}
                  href="https://vk.com/vitamincus"
                  target="_blank"
                  rel="nofollow noreferrer">
                  <IconVK />
                </a>
              </li>
              <li>
                <a
                  className={cssm(s.dzen, 'link')}
                  href="https://dzen.ru/id/623d98ad597c2c26208a333c"
                  target="_blank"
                  rel="nofollow noreferrer">
                  {i18n.__('offer-dzen')} <IconDzen />
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
      {isVideo ? (
        <div className={s.embed__contain}>
          <video preload="auto" no-controls="true" autoPlay loop playsInline muted>
            <source src={mp4} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
          </video>
        </div>
      ) : (
        <CoverBg png={Bg} webp={BgW} />
      )}
    </section>
  );
};

export default Offer;
