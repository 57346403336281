import { Nullable } from "../types/interfaces";

export type CSSMergeProps = {className?: string};
export type CSSMergeMaybeProps = string | CSSMergeProps;

function hasClassName(value: Nullable<CSSMergeMaybeProps>): value is CSSMergeProps {
    return typeof value === "object" && value !== null;
}

export function cssx(selectors: Record<string, boolean | undefined>, append?: string): string {
    const values = Object.entries(selectors)
        .filter(([, selected]) => selected)
        .map(([value]) => value);

    if (append) {
        values.push(append);
    }

    return values.join(" ");
}

export function cssm(...strings: Nullable<CSSMergeMaybeProps>[]): string {
    return strings
        .map((c) => hasClassName(c) ? c.className : c)
        .filter((c) => !!c)
        .join(" ");
}
