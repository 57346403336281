import { FC, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Context } from '../app/Application';
import OgIcon from './../assets/img/og-ico.png';

interface HelmetHeadProps {
  titles: string[];
  descrs: string[];
  img?: string;
}

export const HelmetHead: FC<HelmetHeadProps> = (props) => {
  const { i18n } = useContext(Context);
  const { titles, descrs, img } = props;
  const pathname = useLocation().pathname;
  const currLang = i18n.language;
  const isRu = currLang === 'ru';
  const title = isRu ? titles[0] : titles[1];
  const descr = isRu ? descrs[0] : descrs[1];

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={descr} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={descr} />
      <link rel="canonical" href={`https://vitamincus.me${pathname}`} />
      <meta property="og:url" content={`https://vitamincus.me${pathname}`} />
      {!!img ? (
        <meta property="og:image" content={img} />
      ) : (
        <meta property="og:image" content={OgIcon} />
      )}
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="500" />
    </Helmet>
  );
};
