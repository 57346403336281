import s from './Footer.module.scss';

import { FC, useContext } from 'react';
import Logo from '../../../assets/img/global/logo.svg';
import { IconAddress, IconMail, IconMiniDzen, IconPhone, IconVK } from '../../../components/icons';
import Declaration from '../../../assets/docs/declaration_ru.pdf';
import { Context } from '../../Application';

const Footer: FC = () => {
  const { i18n } = useContext(Context);
  const currLang = i18n.language;
  return (
    <footer className={s.footer}>
      <div className="container">
        <div className={s.wrap}>
          <div className={s.logo}>
            <img src={Logo} alt="vitamincus" />
            <div className={s.address}>
              <IconAddress />
              {i18n.__('footer-address')}
            </div>
          </div>
          <div className={s.contacts}>
            <div className={s.list}>
              <div className={s.label}>{i18n.__('footer-label-1')}</div>
              <a
                href="mailto:sales@vitamincus.me"
                target="_blank"
                className="link"
                rel="noreferrer">
                <IconMail /> sales@vitamincus.me
              </a>
              <a href="tel:84951183234" target="_blank" className="link" rel="noreferrer">
                <IconPhone /> +7 (495) 118-32-34
              </a>
            </div>
            {currLang === 'ru' && (
              <div className={s.list}>
                <div className={s.label}>{i18n.__('footer-label-2')}</div>
                <a
                  href="https://vk.com/vitamincus"
                  target="_blank"
                  className="link"
                  rel="nofollow noreferrer">
                  <IconVK /> Вконтакте
                </a>
                <a
                  href="https://dzen.ru/id/623d98ad597c2c26208a333c"
                  target="_blank"
                  className="link"
                  rel="nofollow noreferrer">
                  <IconMiniDzen />
                  Дзен
                </a>
              </div>
            )}
          </div>
        </div>
        <div className={s.foot}>
          {currLang === 'ru' && (
            <a target="_blank" href={Declaration} rel="noreferrer">
              {i18n.__('footer-link')}
            </a>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
