import s from './Benefits.module.scss';

import { FC, useContext } from 'react';
import ImgP from '../../../../assets/img/sections/benefits-img.png';
import ImgW from '../../../../assets/img/sections/benefits-img.webp';
import { cssm } from '../../../../hooks/css.hooks';
import Picture from '../../../../components/Picture';
import CoverBg from '../../../../components/CoverBg';
import Diplom from '../../../../assets/img/sections/benefits-diplom.png';

import Bg from '../../../../assets/img/sections/benefits-bg.png';
import BgW from '../../../../assets/img/sections/benefits-bg.webp';
import { Context } from '../../../../app/Application';

const Benefits: FC = () => {
  const { i18n } = useContext(Context);
  const currLang = i18n.language;
  const isRu = currLang === 'ru';

  return (
    <>
      <section className={s.benefits}>
        <CoverBg png={Bg} webp={BgW} />
        <div className={cssm(s.container, 'container')}>
          <h2 className={s.title}>{i18n.__('benefits-subtitle')}</h2>
          <div className={s.wrapper}>
            <div className={s.text}>
              <p>{i18n.__('benefits-descr-1')}</p>
              <p>{i18n.__('benefits-descr-2')}</p>
            </div>
            {isRu && (
              <Picture
                className={s.img}
                webp={ImgW}
                png={ImgP}
                alt={isRu ? 'Награды' : 'Benefits'}
              />
            )}
          </div>
        </div>
      </section>
      {isRu && (
        <section className={s.brand}>
          <div className={cssm(s.my_container, 'container')}>
            <img className={s.diplom} src={Diplom} alt="Диплом" />
            <div className={s.wrap}>
              <h2 className={s.title}>Финалист конкурса брендов</h2>
              <div className={s.text}>
                <p>
                  Приветствуем всех наших уважаемых клиентов и партнеров! Мы, команда Vitamincus,
                  хотим поделиться с вами нашим волнующим достижением. В этом году мы стали
                  Финалистами престижного конкурса брендов от Росконгресса и взяли бронзу в
                  номинации "Выбор сообщества"! Мы гордимся этим признанием и благодарим всех за
                  поддержку. Мы предлагаем высококачественные салаты из пророщенных семян и
                  микрозелени, произведенные автоматизированно с использованием передовых технологий
                  и научного подхода. Мы гордимся тем, что наша продукция соответствуют высочайшим
                  стандартам качества и прошла аудиты от ведущих ритейл сетей страны. Мы благодарим
                  наших клиентов и партнеров за доверие и обещаем продолжать развиваться и
                  предлагать инновационные и вкусные ростковые миксы. Спасибо, что вы с нами!
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Benefits;
