import s from './Header.module.scss';

import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skolkovo from '../../../assets/img/sklogo.png';
import LogoColor from '../../../assets/img/global/logo-color.svg';
import { cssm } from '../../../hooks/css.hooks';
import { Context } from '../../Application';

const Header = () => {
  const { i18n } = useContext(Context);
  const [isScrolling, setScrolling] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);

  const config = [
    { name: i18n.__('header-nav-1'), href: '/#catalog' },
    { name: i18n.__('header-nav-2'), href: '/#manufacturing' },
    { name: i18n.__('header-nav-3'), href: '/#delivery' },
    { name: i18n.__('header-nav-4'), to: '/blog' },
  ];

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = useCallback(() => {
    setScrolling(window.pageYOffset > 50);
  }, []);

  return (
    <header className={cssm(isScrolling ? s.scrolling : '', isOpen ? s.open : '', s.header)}>
      <div className={cssm(s.container, 'container')}>
        <Link to="/">
          <img onClick={() => setOpen(false)} className={s.logo} src={LogoColor} alt="Vitamincus" />
        </Link>
        <nav className={s.nav}>
          <a className={s.skolkovo} href="https://sk.ru/" target="_blank" rel="noreferrer">
            <img src={Skolkovo} alt="Skolkovo" />
          </a>
          {config.map((link, i) => {
            if (!!link.to) {
              return (
                <Link onClick={() => setOpen(false)} key={i} to={link.to}>
                  {link.name}
                </Link>
              );
            }
            return (
              <a onClick={() => setOpen(false)} key={i} href={link.href}>
                {link.name}
              </a>
            );
          })}
        </nav>
        <div onClick={() => setOpen((state) => !state)} className={cssm(s.burger, s.active)}>
          <i />
          <i />
          <i />
        </div>
      </div>
    </header>
  );
};

export default Header;
