import { FC, useContext } from 'react';

import Catalog from './sections/Catalog/Catalog';
import About from './sections/About/About';
import Delivery from './sections/Delivery/Delivery';
import Blog from './sections/Blog/Blog';
import Benefits from './sections/Benefits/Benefits';
import VideoSection from './sections/VideoSection/VideoSection';

import Offer from '../../components/Offer/Offer';
import { HelmetHead } from '../../seo';
import { Context } from '../../app/Application';
import SideDish from './sections/SideDish/SideDish';

interface MainPageProps {
  articles: any[];
}

const MainPage: FC<MainPageProps> = (props) => {
  const { i18n } = useContext(Context);
  const { articles } = props;
  return (
    <>
      <HelmetHead
        titles={[
          'Vitamincus - полезные и вкусные салаты из проростков семян и микрозелени',
          'Vitamincus - healthy and tasty salads from sprouted seeds and microgreens',
        ]}
        descrs={[
          'Полезные ростковые салаты на каждый день. Можно заказать с доставкой или приобрести в торговых сетях.',
          'Healthy sprout salads for every day. Can be ordered for delivery or purchased from retailers.',
        ]}
      />
      <main>
        <Offer title={i18n.__('offer-title')} />
        <Catalog />
        <About />
        <Delivery />
        <Blog articles={articles} />
        <Benefits />
        <SideDish />
        <VideoSection />
      </main>
    </>
  );
};

export default MainPage;
